<template>
  <div ref="adContainer" class="ads-container">
    <ins class="adsbygoogle"
         :style="`display:block; width:${containerWidth}px; min-width:250px; height:auto;`"
         data-ad-client="ca-pub-5348394539450529"
         data-ad-slot="4472569800"
         data-ad-format="auto"
         data-full-width-responsive="true"></ins>
  </div>
</template>

<style scoped>
.ads-container {
  text-align: center;
  margin: 10px 0;
  width: 100%; /* Ensures it takes full width of parent */
}
</style>

<script setup>
import { ref, onMounted, nextTick } from "vue";

const adContainer = ref(null);
const containerWidth = ref(300); // Default to 300px

const loadAd = () => {
  if (!window.adsbygoogle) {
    const script = document.createElement("script");
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5348394539450529";
    script.crossOrigin = "anonymous";
    script.async = true;
    script.onload = () => {
      nextTick(() => {
        containerWidth.value = adContainer.value ? adContainer.value.clientWidth : 300;
        window.adsbygoogle.push({});
      });
    };
    document.head.appendChild(script);
  } else {
    nextTick(() => {
      containerWidth.value = adContainer.value ? adContainer.value.clientWidth : 300;
      window.adsbygoogle.push({});
    });
  }
};

onMounted(() => {
  setTimeout(() => {
    containerWidth.value = adContainer.value ? adContainer.value.clientWidth : 300;
    loadAd();
  }, 500); // Small delay ensures layout settles before loading ad
});
</script>

